var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),(_vm.formType == 'mrz')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Тип документа'),"label-for":"v-radio"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.doc_types,"name":"radio-inline"},model:{value:(_vm.form.doc_type),callback:function ($$v) {_vm.$set(_vm.form, "doc_type", $$v)},expression:"form.doc_type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите паспорт'),"label-for":"v-passport"}},[_c('validation-provider',{attrs:{"name":"v-passport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('AA'),expression:"'AA'"}],staticStyle:{"width":"70px"},attrs:{"id":"v-passport","placeholder":"AA"},model:{value:(_vm.form.doc_series),callback:function ($$v) {_vm.$set(_vm.form, "doc_series", $$v)},expression:"form.doc_series"}})]},proxy:true}],null,true)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],attrs:{"id":"v-passport","placeholder":"0000000"},model:{value:(_vm.form.doc_number),callback:function ($$v) {_vm.$set(_vm.form, "doc_number", $$v)},expression:"form.doc_number"}})],1)]}}],null,false,2812390587)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите ПИНФЛ'),"label-for":"v-pin"}},[_c('validation-provider',{attrs:{"name":"v-pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"v-pin","disabled":_vm.hasClient,"placeholder":"00000000000000"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}})]}}],null,false,1971528531)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Имя')}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","name":"name","placeholder":"Имя"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,false,2985283945)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Фамилия'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","name":"name","placeholder":"Фамилия"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,false,2057258014)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Otasinig ismi'),"label-for":"middlename"}},[_c('validation-provider',{attrs:{"name":"middlename","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middlename","name":"name","placeholder":"Otasinig ismi"},model:{value:(_vm.form.middlename),callback:function ($$v) {_vm.$set(_vm.form, "middlename", $$v)},expression:"form.middlename"}})]}}],null,false,2276522396)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('День рождения'),"label-for":"date_birth"}},[_c('validation-provider',{attrs:{"name":"date_birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('x-date-picker',{staticClass:"mr-1 mb-1",attrs:{"placeholder":_vm.$t('День рождения')},model:{value:(_vm.form.date_birth),callback:function ($$v) {_vm.$set(_vm.form, "date_birth", $$v)},expression:"form.date_birth"}})]}}],null,false,843876187)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Hujjat berilgan sana'),"label-for":"date_issue"}},[_c('validation-provider',{attrs:{"name":"date_issue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('x-date-picker',{staticClass:"mr-1 mb-1",attrs:{"placeholder":_vm.$t('Hujjat berilgan sana')},model:{value:(_vm.form.date_issue),callback:function ($$v) {_vm.$set(_vm.form, "date_issue", $$v)},expression:"form.date_issue"}})]}}],null,false,1380981337)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Hujjat amal qilish muddati'),"label-for":"date_expire"}},[_c('validation-provider',{attrs:{"name":"date_expire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"mr-1 mb-1",attrs:{"placeholder":_vm.$t('Hujjat amal qilish muddati'),"type":"date","placement":"bottom-start","format":"dd.MM.yyyy","disabled":_vm.disabled,"picker-options":{ firstDayOfWeek: 1 },"value-format":"yyyy-MM-dd"},model:{value:(_vm.form.date_expire),callback:function ($$v) {_vm.$set(_vm.form, "date_expire", $$v)},expression:"form.date_expire"}})]}}],null,false,3651611264)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите мрз'),"label-for":"v-pin"}},[_c('validation-provider',{attrs:{"name":"v-pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-100",attrs:{"id":"v-pin","placeholder":"P<UZBPALONCHIYEV<<PISTONCHI<<<<<<<<<<<<<<<<<<AA37488592UZB9801071M24111293068394857600000"},model:{value:(_vm.form.mrz),callback:function ($$v) {_vm.$set(_vm.form, "mrz", $$v)},expression:"form.mrz"}}),_c('span',{staticClass:"fs10"},[_vm._v(_vm._s(_vm.mrzplaceholder))])]}}],null,false,166266035)})],1)],1)],1):(_vm.formType == 'manual')?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Тип документа')}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.doc_types,"name":"radio-inline"},model:{value:(_vm.form.doc_type),callback:function ($$v) {_vm.$set(_vm.form, "doc_type", $$v)},expression:"form.doc_type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите паспорт'),"label-for":"v-passport"}},[_c('validation-provider',{attrs:{"name":"v-passport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('AA'),expression:"'AA'"}],staticStyle:{"width":"70px"},attrs:{"id":"v-passport","placeholder":"AA"},model:{value:(_vm.form.doc_series),callback:function ($$v) {_vm.$set(_vm.form, "doc_series", $$v)},expression:"form.doc_series"}})]},proxy:true}],null,true)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],attrs:{"id":"v-passport","placeholder":"0000000"},model:{value:(_vm.form.doc_number),callback:function ($$v) {_vm.$set(_vm.form, "doc_number", $$v)},expression:"form.doc_number"}})],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите ПИНФЛ'),"label-for":"v-pin"}},[_c('validation-provider',{attrs:{"name":"v-pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"v-pin","disabled":_vm.hasClient,"placeholder":"00000000000000"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Имя')}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","name":"name","placeholder":"Имя"},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Фамилия'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","name":"name","placeholder":"Фамилия"},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Otasinig ismi'),"label-for":"middlename"}},[_c('validation-provider',{attrs:{"name":"middlename","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middlename","name":"name","placeholder":"Otasinig ismi"},model:{value:(_vm.form.middlename),callback:function ($$v) {_vm.$set(_vm.form, "middlename", $$v)},expression:"form.middlename"}})]}}])})],1)],1)],1):_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите ПИНФЛ'),"label-for":"v-pin"}},[_c('validation-provider',{attrs:{"name":"v-pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"v-pin","disabled":_vm.hasClient,"placeholder":"00000000000000"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}})]}}])})],1)],1),(_vm.formType == '')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Введите паспорт'),"label-for":"v-passport"}},[_c('validation-provider',{attrs:{"name":"v-passport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('AA'),expression:"'AA'"}],staticStyle:{"width":"70px"},attrs:{"id":"v-passport","placeholder":"AA"},model:{value:(_vm.form.doc_series),callback:function ($$v) {_vm.$set(_vm.form, "doc_series", $$v)},expression:"form.doc_series"}})]},proxy:true}],null,true)},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],attrs:{"id":"v-passport","placeholder":"0000000"},model:{value:(_vm.form.doc_number),callback:function ($$v) {_vm.$set(_vm.form, "doc_number", $$v)},expression:"form.doc_number"}})],1)]}}],null,false,2812390587)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Выберите область'),"label-for":"region_id"}},[_c('validation-provider',{attrs:{"name":"region_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"region_id","name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите область'),"options":_vm.regions.data},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Выберите район'),"label-for":"city_id"}},[_c('validation-provider',{attrs:{"name":"city_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"city_id","name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите район'),"options":_vm.cities.data},model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Адрес'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","name":"name","placeholder":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }