<template>
  <div>
    <br />
    <b-row v-if="formType == 'mrz'">
      <b-col md="12">
        <b-form-group :label="$t('Тип документа')" label-for="v-radio">
          <b-form-radio-group
            v-model="form.doc_type"
            :options="doc_types"
            class="demo-inline-spacing"
            name="radio-inline"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Введите паспорт')" label-for="v-passport">
          <validation-provider
            #default="{ errors }"
            name="v-passport"
            rules="required"
          >
            <b-input-group>
              <template #prepend>
                <b-form-input
                  id="v-passport"
                  v-model="form.doc_series"
                  v-mask="'AA'"
                  placeholder="AA"
                  style="width: 70px"
                />
              </template>

              <b-form-input
                id="v-passport"
                v-model="form.doc_number"
                v-mask="'#######'"
                placeholder="0000000"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Введите ПИНФЛ')" label-for="v-pin">
          <validation-provider
            #default="{ errors }"
            name="v-pin"
            rules="required"
          >
            <b-form-input
              id="v-pin"
              v-model="form.pin"
              v-mask="'##############'"
              :disabled="hasClient"
              placeholder="00000000000000"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Имя')">
          <validation-provider
            #default="{ errors }"
            name="firstname"
            rules="required"
          >
            <b-form-input
              id="firstname"
              v-model="form.firstname"
              name="name"
              placeholder="Имя"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Фамилия')" label-for="lastname">
          <validation-provider
            #default="{ errors }"
            name="lastname"
            rules="required"
          >
            <b-form-input
              id="lastname"
              v-model="form.lastname"
              name="name"
              placeholder="Фамилия"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Otasinig ismi')" label-for="middlename">
          <validation-provider
            #default="{ errors }"
            name="middlename"
            rules="required"
          >
            <b-form-input
              id="middlename"
              v-model="form.middlename"
              name="name"
              placeholder="Otasinig ismi"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('День рождения')" label-for="date_birth">
          <validation-provider
            #default="{ errors }"
            name="date_birth"
            rules="required"
          >
            <x-date-picker
              v-model="form.date_birth"
              :placeholder="$t('День рождения')"
              class="mr-1 mb-1"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('Hujjat berilgan sana')"
          label-for="date_issue"
        >
          <validation-provider
            #default="{ errors }"
            name="date_issue"
            rules="required"
          >
            <x-date-picker
              v-model="form.date_issue"
              :placeholder="$t('Hujjat berilgan sana')"
              class="mr-1 mb-1"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          :label="$t('Hujjat amal qilish muddati')"
          label-for="date_expire"
        >
          <validation-provider
            #default="{ errors }"
            name="date_expire"
            rules="required"
          >
            <!-- <x-date-picker
              v-model="form.date_expire"
              :placeholder="$t('Hujjat amal qilish muddati')"
              class="mr-1 mb-1"
            /> -->
            <el-date-picker
              v-model="form.date_expire"
              class="mr-1 mb-1"
              :placeholder="$t('Hujjat amal qilish muddati')"
              type="date"
              placement="bottom-start"
              format="dd.MM.yyyy"
              :disabled="disabled"
              :picker-options="{ firstDayOfWeek: 1 }"
              value-format="yyyy-MM-dd"
            />
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group :label="$t('Введите мрз')" label-for="v-pin">
          <validation-provider
            #default="{ errors }"
            name="v-pin"
            rules="required"
          >
            <b-form-input
              id="v-pin"
              v-model="form.mrz"
              class="w-100"
              placeholder="P<UZBPALONCHIYEV<<PISTONCHI<<<<<<<<<<<<<<<<<<AA37488592UZB9801071M24111293068394857600000"
            />
            <span class="fs10">{{ mrzplaceholder }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else-if="formType == 'manual'">
      <b-col md="12">
        <b-form-group :label="$t('Тип документа')">
          <b-form-radio-group
            v-model="form.doc_type"
            :options="doc_types"
            class="demo-inline-spacing"
            name="radio-inline"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Введите паспорт')" label-for="v-passport">
          <validation-provider
            #default="{ errors }"
            name="v-passport"
            rules="required"
          >
            <b-input-group>
              <template #prepend>
                <b-form-input
                  id="v-passport"
                  v-model="form.doc_series"
                  v-mask="'AA'"
                  placeholder="AA"
                  style="width: 70px"
                />
              </template>

              <b-form-input
                id="v-passport"
                v-model="form.doc_number"
                v-mask="'#######'"
                placeholder="0000000"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Введите ПИНФЛ')" label-for="v-pin">
          <validation-provider
            #default="{ errors }"
            name="v-pin"
            rules="required"
          >
            <b-form-input
              id="v-pin"
              v-model="form.pin"
              v-mask="'##############'"
              :disabled="hasClient"
              placeholder="00000000000000"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Имя')">
          <validation-provider
            #default="{ errors }"
            name="firstname"
            rules="required"
          >
            <b-form-input
              id="firstname"
              v-model="form.firstname"
              name="name"
              placeholder="Имя"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Фамилия')" label-for="lastname">
          <validation-provider
            #default="{ errors }"
            name="lastname"
            rules="required"
          >
            <b-form-input
              id="lastname"
              v-model="form.lastname"
              name="name"
              placeholder="Фамилия"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Otasinig ismi')" label-for="middlename">
          <validation-provider
            #default="{ errors }"
            name="middlename"
            rules="required"
          >
            <b-form-input
              id="middlename"
              v-model="form.middlename"
              name="name"
              placeholder="Otasinig ismi"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="6">
        <b-form-group :label="$t('Введите ПИНФЛ')" label-for="v-pin">
          <validation-provider
            #default="{ errors }"
            name="v-pin"
            rules="required"
          >
            <b-form-input
              id="v-pin"
              v-model="form.pin"
              v-mask="'##############'"
              :disabled="hasClient"
              placeholder="00000000000000"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col v-if="formType == ''" md="6">
        <b-form-group :label="$t('Введите паспорт')" label-for="v-passport">
          <validation-provider
            #default="{ errors }"
            name="v-passport"
            rules="required"
          >
            <b-input-group>
              <template #prepend>
                <b-form-input
                  id="v-passport"
                  v-model="form.doc_series"
                  v-mask="'AA'"
                  placeholder="AA"
                  style="width: 70px"
                />
              </template>

              <b-form-input
                id="v-passport"
                v-model="form.doc_number"
                v-mask="'#######'"
                placeholder="0000000"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('Выберите область')" label-for="region_id">
          <validation-provider
            #default="{ errors }"
            name="region_id"
            rules="required"
          >
            <b-form-select
              id="region_id"
              v-model="form.region_id"
              name="name"
              value-field="id"
              text-field="name_uz"
              :placeholder="$t('Выберите область')"
              :options="regions.data"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Выберите район')" label-for="city_id">
          <validation-provider
            #default="{ errors }"
            name="city_id"
            rules="required"
          >
            <b-form-select
              id="city_id"
              v-model="form.city_id"
              name="name"
              value-field="id"
              text-field="name_uz"
              :placeholder="$t('Выберите район')"
              :options="cities.data"
            />
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Адрес')" label-for="address">
          <validation-provider
            #default="{ errors }"
            name="address"
            rules="required"
          >
            <b-form-input
              id="address"
              v-model="form.address"
              name="name"
              placeholder=""
            />
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    hasClient: {
      type: Boolean,
      default: () => false,
    },
    formType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      cities: [],
      required,
      mrzplaceholder:
        "P<UZBPALONCHIYEV<<PISTONCHI<<<<<<<<<<<<<<<<<<AA37488592UZB9801071M2411129306839485760000",
    };
  },
  computed: {
    ...mapGetters({
      regions: "region/GET_ITEMS",
    }),
    doc_types() {
      return [
        { text: "Паспорт", value: 6 },
        { text: "ID-Card", value: 0 },
      ];
    },
    isPinFull() {
      return this.form.pin && this.form.pin.length > 13;
    },
  },
  watch: {
    "form.doc_series": function (val) {
      if (val) {
        this.form.doc_series = val.toUpperCase();
      }
    },
    "form.mrz": function (val) {
      if (val) {
        this.form.mrz = val.toUpperCase();
      }
    },
    "form.region_id": function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.fetchCities({ region_id: newVal }).then((res) => {
          this.cities = res.data;
        });
      }
    },
    isPinFull(val) {
      if (val) {
        this.$emit("isPinFull", true);
      }
    },
  },
  mounted() {
    if (!(this.regions && this.regions.length)) {
      this.getRegionsAction({ per_page: 999 });
    }
  },
  methods: {
    ...mapActions({
      getRegionsAction: "region/index",
      fetchCities: "city/index",
    }),
  },
};
</script>

<style></style>
