<template>
  <b-row>
    <b-col md="8">
      <b-card>
        <b-overlay :show="loading">
          <validation-observer
            ref="form"
            #default="{ invalid }"
          >
            <b-form @submit.prevent>
              <!--  -->
              <b-row>
                <b-col md="4">
                  <b-form-group
                    :label="$t('Введите телефон')"
                    label-for="v-phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Введите телефон')"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          +998
                        </b-input-group-prepend>

                        <b-form-input
                          id="v-phone"
                          v-model="form.phone"
                          v-mask="'#########'"
                          name="v-phone"
                          :state="errors.length > 3 ? false : null"
                          :disabled="phone_confirmed"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="isPhoneFull && confirmWithoutSms"
                  md="4"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2"
                    @click="phone_confirmed = true"
                  >
                    {{ $t("Продолжить без подтверждения") }}
                  </b-button>
                </b-col>
                <b-col
                  v-else-if="isPhoneFull && !is_sent_sms"
                  md="3"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2"
                    @click="sendSms"
                  >
                    {{ $t("Получить смс") }}
                  </b-button>
                </b-col>
                <template v-if="is_sent_sms && !phone_confirmed">
                  <b-col md="4">
                    <b-form-group
                      :label="$t('Введите код из СМС')"
                      label-for="sms"
                    >
                      <b-form-input
                        id="sms"
                        v-model="form.code"
                        v-mask="'####'"
                        type="number"
                        placeholder="0000"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-button
                      v-if="form.code && form.code.length > 3"
                      type="submit"
                      variant="primary"
                      class="mt-2"
                      @click="checkSms"
                    >
                      {{ $t("Отправить") }}
                    </b-button>
                  </b-col>
                </template>
              </b-row>
              <!-- Manual Create -->
              <span
                v-if="openManual"
                class="link"
                @click="setFormType('manual')"
              >{{
                formType=='manual' ? "KATM orqali kiritish" : "Qo'lda kiritish"
              }}</span>
              <b-row v-if="openExceptionCode && !exception_confirmed">
                <template>
                  <b-col md="5">
                    <b-form-group
                      :label="$t('Введите код из СМС')"
                      label-for="sms"
                    >
                      <b-form-input
                        id="sms"
                        v-model="form.exception_code"
                        v-mask="'####'"
                        type="number"
                        placeholder="0000"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="5">
                    <b-button
                      v-if="form.exception_code && form.exception_code.length > 3"
                      type="submit"
                      variant="primary"
                      class="mt-2"
                      @click="checkExceptionSms"
                    >
                      {{ $t("Отправить") }}
                    </b-button>
                  </b-col>
                </template>
              </b-row>
              <hr>
              <!-- citizen details -->
              <citizen-details
                :form="form"
                :has-client="hasClient"
                :class="phone_confirmed ? '' : 'double-overlay'"
                :form-type="formType"
                @isPinFull="checkClient"
              />

              <hr>
              <b-row :class="phone_confirmed ? '' : 'double-overlay'">
                <b-col
                  md="12"
                  class="text-right"
                >
                  <!-- Mrz Create -->
                  <span
                    v-if="openManual"
                    class="link float-left"
                    @click="setFormType('mrz')"
                  >{{
                    formType=='mrz' ? "KATM orqali kiritish" : "MRZ orqali kiritish"
                  }}</span>
                  <!-- Katm create -->
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="save()"
                  >
                    {{ $t("Сохранить") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card>
    </b-col>

    <b-col
      v-if="hasClient"
      md="4"
    >
      <b-card>
        <p><b>ФИО:</b> {{ client_data.fullname }}</p>
        <p><b>Адрес:</b> {{ client_data.address }}</p>
        <p>
          <b>Дата рождения:</b>
          {{ client_data.birthdate ? formatDate(client_data.birthdate) : "" }}
        </p>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/utils/toast'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@validations'
import { formatDate } from '@core/utils/filter'
import CitizenDetails from './components/citizen-details.vue'

export default {
  components: {
    BCardCode,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    CitizenDetails,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      formatDate,
    }
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formType: '',
      is_sent_sms: false,
      phone_confirmed: false,
      loading: false,
      required,
      hasClient: false,
      confirmWithoutSms: false,
      openManual: false,
      isManual: false,
      openExceptionCode: false,
      exception_confirmed: false,
      form: {
        exception_code: null,
        phone: null,
        code: null,
        pin: null,
        region_id: null,
        city_id: null,
        address: '',
        mrz: '',
        doc_type: 6,
        firstname: '',
        lastname: '',
        middlename: '',
        date_birth: null,
        date_issue: null,
        date_expire: null,
        doc_series: null,
        doc_number: null,
      },
      client_data: {
        fullname: null,
        address: '',
        birthdate: '',
      },
    }
  },
  computed: {
    ...mapGetters({ settings: 'setting/GET_ITEMS' }),
    isPhoneFull() {
      return this.form.phone && this.form.phone.length >= 9
    },
  },
  watch: {
    isPhoneFull(val) {
      if (!val) {
        this.is_sent_sms = false
      }
    },
  },
  mounted() {
    this.fetchSettings().then(() => {
      const smsConf = this.settings.data.find(
        c => c.code == 'sms_confirmation_required',
      )
      this.confirmWithoutSms = smsConf.value === '0'
    })
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      clientCreate: 'client/create',
      clientCreateManual: 'client/createManual',
      clientStoreManualNonKatm: 'client/clientStoreManualNonKatm',
      sendForExceptionSituations: 'resource/sendForExceptionSituations',
      confirmForExceptionSituations: 'resource/confirmForExceptionSituations',
      getClient: 'client/clients',
      fetchSettings: 'setting/index',
      sendSmsAction: 'resource/sendSms',
      confirmSms: 'resource/confirmSms',
    }),
    sendSms() {
      this.is_sent_sms = true
      this.sendSmsAction({ phone: this.form.phone })
      // .catch(() => {
      //   showToast('danger', this.$t('Ошибка'), 'XIcon')
      // })
    },
    checkClient() {
      const data = {}
      if (this.form.phone) {
        data.phone = this.form.phone
      }
      if (this.form.pin) {
        data.pin = this.form.pin
      }
      this.getClient(data).then(res => {
        if (res.data && res.data.data && res.data.data.length) {
          this.setForm(res.data.data[0])
        }
      })
    },
    checkSms() {
      this.confirmSms({ phone: this.form.phone, code: this.form.code })
        .then(res => {
          if (res.success) {
            this.checkClient()
            this.phone_confirmed = true
          }
        })
        .catch(err => {
          showToast('danger', err.data.message, 'XIcon')
        })
    },
    checkExceptionSms() {
      const expData = {
        exception_situation_id: 3,
        code: this.form.exception_code,
      }
      this.confirmForExceptionSituations(expData)
        .then(res => {
          if (res.success) {
            this.exception_confirmed = true
            this.formType = 'manual'
          }
        })
        .catch(err => {
          showToast('danger', err.data.message, 'XIcon')
        })
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    createMethod(data) {
      if (this.formType == 'mrz') {
        return this.clientCreateManual(data)
      } if (this.formType == 'manual') {
        return this.clientStoreManualNonKatm(data)
      }
      return this.clientCreate(data)
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.form.phone.slice(0, 4)
        this.loading = true
        this.createMethod(this.form)
          .then(res => {
            if (res.success) {
              this.loading = false
              showToast(
                'success',
                this.$t("Foydalanuvchi muvaffaqiyatli qo'shildi"),
              )
              if (this.isComponent) {
                this.$emit('close', { data: res.data })
              } else {
                this.$router.push({
                  name: 'client-show',
                  params: { id: res.data.id },
                })
              }
            }
          })
          .catch(() => {
            showToast('danger', this.$t('Ошибка'), 'XIcon')
            this.openManual = true
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    setForm(data) {
      this.form.region_id = data.region_id
      this.form.city_id = data.city_id
      this.form.address = data.address
      this.form.doc_type = data.doc_type
      this.form.doc_series = data.doc_series
      this.form.doc_number = data.doc_number
      if (!this.form.pin) {
        this.form.pin = data.pin
      }
      this.client_data.fullname = [
        data.lastname,
        data.firstname,
        data.middlename,
      ].join(' ')
      this.client_data.address = `${data.region.name_uz}, ${data.city.name_uz}`
      this.client_data.birthdate = data.date_birth

      this.hasClient = true
    },
    setFormType(type) {
      if (type == 'manual') {
        this.sendForExceptionSituations()
        this.openExceptionCode = true
      } else if (type == this.formType) {
        this.formType = ''
      } else {
        this.formType = type
      }
    },
  },
}
</script>
